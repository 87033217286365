import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { LOCATION_VIEW } from '../constans/locations';
import destinationsService from '../services/destinationsService';
import BreadcrumbsLocations from '../components/BreadcrumbsActivity/BreadcrumbsLocations';
import {
  generateActivitiesOrPropertiesLinks,
  generateLinkPath,
  generateShortLocationLinkPath,
} from '../helpers/generateLinks';
import { getIds, getLocationView } from '../helpers/getIds';
import styles from './index.module.scss';
import { getTitle } from '../helpers/getLocationFromUrl';
import { SeoData, SeoTitle } from '../components/StructuredData/StructuredData';
import LinkIcon from '../assets/img/icons/icon-Link.svg';

const excludeLocations = ['All Regions', 'All Cities', 'All Districts'];

function filterObjectsByExcludedLocations(objects) {
  return objects.filter((obj) => {
    const { name } = obj;
    return !excludeLocations.some((excludeLocation) =>
      name.includes(excludeLocation)
    );
  });
}

function LocationTemplate({ pageContext }) {
  const { destination, urlPath } = pageContext;
  const country = destination.name;
  const region = destination.region?.name || undefined;
  const city = destination.region?.city?.name || undefined;
  const district = destination.region?.city?.district?.name || undefined;
  const [locations, setLocations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [properties, setProperties] = useState([]);

  const locationView = getLocationView(region, city, district);
  const ids = getIds(destination, region, city, district);

  useEffect(() => {
    const fetchData = async () => {
      if (locationView === LOCATION_VIEW.COUNTRY) {
        const { data } = await destinationsService().getRegions(ids.countryId);
        const filteredArray = await filterObjectsByExcludedLocations(data);
        setLocations(filteredArray);
      }

      if (locationView === LOCATION_VIEW.REGION) {
        const { data } = await destinationsService().getCities(ids.regionId);
        const filteredArray = await filterObjectsByExcludedLocations(data);
        setLocations(filteredArray);
      }

      if (locationView === LOCATION_VIEW.CITY) {
        const { data } = await destinationsService().getDistricts(ids.cityId);
        const filteredArray = await filterObjectsByExcludedLocations(data);
        setLocations(filteredArray);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const getListOfActivities = async (offset) => {
      const {
        data,
        pagination,
      } = await destinationsService().getDestinationActivities({
        ...ids,
        offset,
        limit: 100,
      });

      setActivities((prevActivities) => [...prevActivities, ...data]);

      if (
        pagination.nextPage &&
        pagination.nextPage <= pagination.totalCount / 100 + 1
      ) {
        getListOfActivities(pagination.nextOffset);
      }
    };

    getListOfActivities(0);
  }, []);

  useEffect(() => {
    const getListOfProperties = async (offset) => {
      const {
        data,
        pagination,
      } = await destinationsService().getDestinationProperties({
        ...ids,
        offset,
        limit: 100,
      });

      setProperties((prevProperties) => [...prevProperties, ...data]);

      if (
        pagination.nextPage &&
        pagination.nextPage <= pagination.totalCount / 100 + 1
      ) {
        getListOfProperties(pagination.nextOffset);
      }
    };

    getListOfProperties(0);
  }, []);

  return (
    <Layout mainClasses="section-padded-wrapper flex-grow-1">
      <SeoData urlPath={urlPath} />
      <SeoTitle
        title={`List of destinations, activities and properties in ${
          district || city || region || country
        } on Koralgo.com`}
      />
      <div className={styles.container}>
        <h2>
          List of destinations, activities and properties in{' '}
          {district || city || region || country} on Koralgo.com
        </h2>
        <BreadcrumbsLocations
          country={country}
          region={region}
          city={city}
          district={district}
          target="_self"
        />
        <div className={styles.wrapper}>
          <div className={styles.column}>
            {locations.length > 0 && (
              <>
                <h4>
                  {`${locations.length} ${getTitle(locationView)} ${
                    district || city || region || country
                  }`}
                </h4>
                {locations.map((item) => (
                  <div className={styles.block} key={item.name}>
                    <a href={generateLinkPath('locations', destination, item)}>
                      {item.name}
                    </a>

                    <a
                      href={generateShortLocationLinkPath(
                        destination,
                        item.name
                      )}
                    >
                      <LinkIcon />
                    </a>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={styles.column}>
            <h4>
              {`${activities.length} activities in  ${
                district || city || region || country
              }`}
            </h4>
            {activities.map((activity) => {
              const patch = generateActivitiesOrPropertiesLinks(
                'activities',
                activity
              );
              return (
                <div className={styles.block} key={activity.title}>
                  <Link to={patch}>{activity.title}</Link>
                </div>
              );
            })}
          </div>
          <div className={styles.column}>
            <h4>
              {`${properties.length} properties in  ${
                district || city || region || country
              }`}
            </h4>
            {properties.map((property) => {
              const patch = generateActivitiesOrPropertiesLinks(
                'properties',
                property
              );
              return (
                <div className={styles.block} key={property.title}>
                  <Link to={patch}>{property.title}</Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LocationTemplate;
