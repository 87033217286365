import { LOCATION_VIEW } from '../constans/locations';

export const getIds = (destination, region, city, district) => {
  const ids = {
    countryId: destination.id,
  };
  if (region) {
    ids.regionId = destination.region.id;
  }
  if (city) {
    ids.cityId = destination.region.city.id;
  }
  if (district) {
    ids.districtId = destination.region.city.district.id;
  }
  return ids;
};

export const getLocationView = (region, city, district) => {
  let locationView = LOCATION_VIEW.COUNTRY;

  if (district) {
    locationView = LOCATION_VIEW.DISTRICT;
  }
  if (city && locationView !== LOCATION_VIEW.DISTRICT) {
    locationView = LOCATION_VIEW.CITY;
  }
  if (region && locationView !== LOCATION_VIEW.DISTRICT && locationView !== LOCATION_VIEW.CITY) {
    locationView = LOCATION_VIEW.REGION;
  }
  return locationView;
};
