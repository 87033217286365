import React from 'react';

import BreadcrumbsItem from './BreadcrumbsItem/BreadcrumbsItem';

import styles from './BreadcrumbsActivity.module.scss';
import { cleanedUrl } from '../../constans/locations';

function BreadcrumbsLocations({ country, region, city, district, target }) {
  return (
    <div className={styles.breadcrumbsWrapper}>
      <BreadcrumbsItem
        href="/locations"
        name="Countries"
        isActive={!country}
        target={target}
      />
      <BreadcrumbsItem
        href={`/locations/${cleanedUrl(country)}`}
        name={country}
        isActive={!region}
        target={target}
      />
      {region && (
        <BreadcrumbsItem
          href={`/locations/${cleanedUrl(country)}/${cleanedUrl(region)}`}
          name={region}
          isActive={!city}
          target={target}
        />
      )}
      {city && (
        <BreadcrumbsItem
          href={`/locations/${cleanedUrl(country)}/${cleanedUrl(
            region
          )}/${cleanedUrl(city)}`}
          name={city}
          isActive={!district}
          target={target}
        />
      )}
      {district && (
        <BreadcrumbsItem
          href={`/locations/${cleanedUrl(country)}/${cleanedUrl(
            region
          )}/${cleanedUrl(city)}/${cleanedUrl(district)}`}
          name={district}
          isActive={district}
          target={target}
        />
      )}
    </div>
  );
}

export default BreadcrumbsLocations;
